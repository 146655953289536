<template>
  <div style="height: 100%;width: 100%">

  <div v-if="isPc"
       style="width: 100%;height: 100%;padding: 24px;display: flex;justify-content: center;align-items: center;flex-wrap: wrap">
    <div style="width: 100%">
      <div style="height:50px;letter-spacing:1px;display: flex;align-items: center">
        <a-icon type="shopping-cart"
                style="color: rgb(79,142,251);font-size: 23px;margin-right: 5px;margin-bottom: 2px"/>
        <p style="font-weight: bold;font-size: 20px">选择商品</p>
      </div>

      <a-radio-group v-model="selectProduct" style="height: 20%;display: flex;" @change="selectGoods">
        <div style="height: 20%;width: 25%;margin: 5px;" v-for="(item,i) in Goods" :key="i">

          <a-radio-button :value="item.id"
                          style="height: 160px;margin: 5px;border-radius: 19px;width: 100%;display: flex;justify-content: center;align-items: center">

            <div style="width:90%;display: flex;margin-top: 15px;justify-content: center;">
              <div style="font-size: 25px;margin-top: -8px">￥</div>
              <div style="font-size: 49px;font-weight: bold;margin-left: -3px">{{ item.price[0] }}</div>
              <div style="margin-top: -8px">
                <div style="font-size: 25px;font-weight:600;">.{{ item.price[1] != undefined ? item.price[1] : '00' }}
                </div>
                <div style="margin-top: -5px;text-align: right;font-size: 15px;font-weight: 600">/起</div>
              </div>

            </div>
            <div style="width: 100%;margin-bottom: 15px;margin-top:10px;display: flex;justify-content: center">
              <a-tag color="blue"
                     style="border-radius: 19px;padding: 5px 15px;font-size: 14px;">
                {{ item.introduce }}
              </a-tag>
            </div>

          </a-radio-button>

        </div>


      </a-radio-group>
    </div>

    <div style="width: 100%;">
      <div style="height:50px;margin-top: 10px;;letter-spacing:1px;display: flex;align-items: center">
        <a-icon type="dollar"
                style="color: rgb(79,142,251);font-size: 23px;margin-right: 5px;margin-bottom: 2px"/>
        <p style="font-weight: bold;font-size: 20px">支付方式</p>
      </div>

      <a-radio-group v-model="selectPayfor" style="height: 20%;display: flex;">
        <div style="height: 20%;width: 25%;margin: 5px;">
          <a-radio-button :value="1"
                          style="height: 40px;margin: 5px;border-radius: 19px;width: 100%;display: flex;font-size: 18px;font-weight: 600;justify-content: center;align-items: center">
            <a-icon type="alipay-circle"/>&nbsp;支付宝支付
          </a-radio-button>
        </div>
        <div style="height: 20%;width: 25%;margin: 5px;">

          <a-radio-button :value="2"
                          style="height: 40px;margin: 5px;border-radius: 19px;width: 100%;font-size: 18px;font-weight: 600;display: flex;justify-content: center;align-items: center">

            <a-icon type="wechat"/>&nbsp;微信支付
          </a-radio-button>
        </div>

      </a-radio-group>
    </div>

    <div style="width: 100%;">
      <div style="height:50px;margin-top: 10px;;letter-spacing:1px;display: flex;align-items: center">
        <a-icon type="book"
                style="color: rgb(79,142,251);font-size: 23px;margin-right: 5px;margin-bottom: 2px"/>
        <p style="font-weight: bold;font-size: 20px">会员介绍</p>
      </div>
      <div style="font-size: 19px;font-weight: 600;color: red">
        <div style="float: right;">
          <div style="text-align: center;color: rgba(0,0,0,0.41);font-size: 15px;font-weight: 600">商品名称</div>
          <div style="text-align: center;color: rgba(0,0,0,0.65);font-size: 19px;font-weight: 600">{{
              this.productName
            }}
          </div>
          <div style="text-align: center;color: rgba(0,0,0,0.41);font-size: 15px;font-weight: 600">总价</div>
          <div style="text-align: center;color: rgba(0,0,0,0.41);font-size: 19px;font-weight: 600">￥{{ productPrice }}
          </div>
          <div style="text-align: center;color: rgba(0,0,0,0.41);font-size: 19px;font-weight: 600;opacity: 0">1</div>
          <a-button style="width: 200px;font-size: 16px;font-weight: 600" type="danger" ghost @click="BindWS">立即支付
          </a-button>
        </div>

        <p>开通会员可以使用很多功能,量大联系微信:yinsu688</p>
        <p>可以选择宽图模式和普通模式，自定义拖动饰品位置</p>
        <p>可以自定义背景图、背景色、顶部描述、添加素材库等...</p>
        <p>一个Steam17位ID付费一次，可以免费使用7天会员做图功能</p>
      </div>
    </div>

    <a-modal v-model="isOpenWs" :title="pay_success ? '支付成功'  :'待支付'" @cancel="closeModal" :footer="null"
             :maskClosable="false" :keyboard="false"
    >


      <div v-if="qrcode == null"
           style="width: 100%;height: 300px;display: flex;justify-content: center;align-items: center">
        <a-spin style="transform: scale(3)"/>
      </div>
      <div style="font-size: 20px;font-weight: 600;display: flex;justify-content: space-between;align-items: center">
        <div>商品名称:{{ this.productName }}</div>
        <div>商品价格:{{ this.productPrice }}</div>
      </div>

      <div style="width:100%;height: 100%;display: flex;justify-content: center" v-if="!pay_success">
        <img :src="qrcode" height="100%" alt="">
      </div>
      <a-result v-if="pay_success"
                status="success"
                title="支付成功！"
                :sub-title="`订单号: ${oid}，稍等一会儿就会到账！`"
      >
        <template #extra>
          <a-button key="backMenu" type="primary" @click="$router.push('/personality/home')">
            返回主页
          </a-button>
          <a-button key="close" @click="isOpenWs =false">
            关闭
          </a-button>
        </template>
      </a-result>

    </a-modal>
  </div>

    <div v-if="!isPc"
         style="width: 100%;padding: 24px;display: flex;justify-content: center;align-items: center;flex-wrap: wrap;">
      <div style="width: 100%">
        <div style="height:50px;letter-spacing:1px;display: flex;align-items: center">
          <a-icon type="shopping-cart"
                  style="color: rgb(79,142,251);font-size: 23px;margin-right: 5px;margin-bottom: 2px"/>
          <p style="font-weight: bold;font-size: 20px">选择商品</p>
        </div>

        <a-radio-group v-model="selectProduct" style="height: 20%;display: flex;flex-wrap: wrap;align-items: center" @change="selectGoods">
          <div style="height: 20%;width: 47%;margin: 6px;" v-for="(item,i) in Goods" :key="i">

            <a-radio-button :value="item.id"
                            style="height: 110px;margin: 5px;border-radius: 19px;width: 100%;display: flex;justify-content: center;align-items: center">

              <div style="width:90%;display: flex;margin-top: 15px;justify-content: center;">
                <div style="font-size: 21px;margin-top: -8px">￥</div>
                <div style="font-size: 45px;font-weight: bold;margin-left: -3px">{{ item.price[0] }}</div>
                <div style="margin-top: -8px">
                  <div style="font-size: 21px;font-weight:600;">.{{ item.price[1] != undefined ? item.price[1] : '00' }}
                  </div>
                  <div style="margin-top: -5px;text-align: right;font-size: 15px;font-weight: 600">/起</div>
                </div>

              </div>
              <div style="width: 100%;margin-bottom: 15px;margin-top:10px;display: flex;justify-content: center">
                <a-tag color="blue"
                       style="border-radius: 19px;padding: 5px 15px;font-size: 12px;">
                  {{ item.introduce }}
                </a-tag>
              </div>

            </a-radio-button>

          </div>


        </a-radio-group>
      </div>

      <div style="width: 100%;">
        <div style="height:50px;margin-top: 10px;;letter-spacing:1px;display: flex;align-items: center">
          <a-icon type="dollar"
                  style="color: rgb(79,142,251);font-size: 23px;margin-right: 5px;margin-bottom: 2px"/>
          <p style="font-weight: bold;font-size: 20px">支付方式</p>
        </div>

        <a-radio-group v-model="selectPayfor" style="height: 20%;display: flex;">
          <div style="height: 20%;width: 46%;margin: 5px;">
            <a-radio-button :value="1"
                            style="height: 40px;margin: 5px;border-radius: 19px;width: 100%;display: flex;font-size: 18px;font-weight: 600;justify-content: center;align-items: center">
              <a-icon type="alipay-circle"/>&nbsp;支付宝支付
            </a-radio-button>
          </div>

          <div style="height: 20%;width: 46%;margin: 5px;">

            <a-radio-button :value="2"
                            style="height: 40px;margin: 5px;border-radius: 19px;width: 100%;font-size: 18px;font-weight: 600;display: flex;justify-content: center;align-items: center">

              <a-icon type="wechat"/>&nbsp;微信支付
            </a-radio-button>
          </div>

        </a-radio-group>
      </div>

      <div style="width: 100%;">
        <div style="height:50px;margin-top: 10px;;letter-spacing:1px;display: flex;align-items: center">
          <a-icon type="book"
                  style="color: rgb(79,142,251);font-size: 23px;margin-right: 5px;margin-bottom: 2px"/>
          <p style="font-weight: bold;font-size: 20px">会员介绍</p>
        </div>

        <div style="font-size: 19px;font-weight: 600;color: red">

          <p>开通会员可以使用很多功能,量大联系微信:yinsu688</p>
          <p>可以选择宽图模式和普通模式，自定义拖动饰品位置</p>
          <p>可以自定义背景图、背景色、顶部描述、添加素材库等...</p>
          <p>一个Steam17位ID付费一次，可以免费使用7天会员做图功能</p>

        </div>

        <div>
          <div style="text-align: center;color: rgba(0,0,0,0.41);font-size: 16px;font-weight: 600">商品名称</div>
          <div style="text-align: center;color: rgba(0,0,0,0.65);font-size: 21px;font-weight: 600">{{
              this.productName
            }}
          </div>
          <div style="text-align: center;color: rgba(0,0,0,0.41);font-size: 16px;font-weight: 600">总价</div>
          <div style="text-align: center;color: rgba(0,0,0,0.41);font-size: 21px;font-weight: 600">￥{{ productPrice }}
          </div>
          <div style="text-align: center;color: rgba(0,0,0,0.41);font-size: 19px;font-weight: 600;opacity: 0">1</div>
          <a-button style="width:100%;font-size: 16px;font-weight: 600;height: 50px" type="danger" ghost @click="BindWS">立即支付
          </a-button>
        </div>


      </div>


      <a-modal v-model="isOpenWs" :title="pay_success ? '支付成功'  :'待支付'" @cancel="closeModal" :footer="null"
               :maskClosable="false" :keyboard="false"
      >


        <div v-if="qrcode == null"
             style="width: 100%;height: 300px;display: flex;justify-content: center;align-items: center">
          <a-spin style="transform: scale(3)"/>
        </div>
        <div style="font-size: 20px;font-weight: 600;display: flex;justify-content: space-between;align-items: center">
          <div>商品名称:{{ this.productName }}</div>
          <div>商品价格:{{ this.productPrice }}</div>
        </div>

        <div style="width:100%;height: 100%;display: flex;justify-content: center" v-if="!pay_success">
          <img :src="qrcode" height="100%" alt="">
        </div>
        <a-result v-if="pay_success"
                  status="success"
                  title="支付成功！"
                  :sub-title="`订单号: ${oid}，稍等一会儿就会到账！`"
        >
          <template #extra>
            <a-button key="backMenu" type="primary" @click="$router.push('/personality/home')">
              返回主页
            </a-button>
            <a-button key="close" @click="isOpenWs =false">
              关闭
            </a-button>
          </template>
        </a-result>

      </a-modal>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";

const key = '123'

export default {
  name: "Payment",
  data() {
    return {
      productList: [],

      selectProduct: 1,
      selectPayfor: 1,
      productName: '',
      productPrice: '',

      Ws: null,
      isOpenWs: false,
      clientId: '',
      pay_success: false,
      isLoading: false,
      userClose: true,
      isPc: true,
      oid: '',
      qrcode: '',
    }
  },
  computed: {
    ...mapState('Goods', ['Goods'])
  },
  methods: {
    closeModal() {
      if (this.userClose) {
        this.isOpenWs = false
        this.qrcode = null
        this.pay_success = false
        this.$message.warning({content: '用户取消支付', key, duration: 2})
        this.userClose = false
        return
      }
      if (!this.pay_success) {
        this.$message.loading({content: '正在查询状态', key, duration: 0})
        if (this.selectPayfor == 1) {
          this.queryAlipay()
        } else {
          this.queryWxPay()
        }
      }
    },


    async queryAlipay() {
      await this.$axios.post('/Api/PayMent/Buy/findOidByAliPay', {
        oid: this.oid
      }).then(() => {
        this.pay_success = true
        this.$message.success({content: '支付成功', key, duration: 2})
      }).catch(e => {
        console.log(e)

        this.$message.error({content: e.msg, key, duration: 2})
      })
    },

    async queryWxPay() {
      await this.$axios.post('/Api/PayMent/Buy/findOidByWechat', {
        oid: this.oid
      }).then(() => {
        this.pay_success = true
        this.$message.success({content: '支付成功', key, duration: 2})
      }).catch(e => {
        console.log(e)
        this.$message.error({content: e.msg, key, duration: 2})
      })
    },
    selectGoods() {
      const temp = this.Goods.filter(v => v.id == this.selectProduct)[0]
      this.productName = temp.name
      this.productPrice = `${temp.price[0]}.${temp.price[1] != undefined ? temp.price[1] : '00'}`
    },
    async BindWS() {

      this.clientId = ''
      this.isOpenWs = false
      this.pay_success = false
      this.userClose = true
      this.oid = ''
      this.qrcode = null
      this.$message.loading({content: '正在与服务器通信', key, duration: 0})

      if (this.selectPayfor == 1) {
        await this.createAlipay()
      } else {
        await this.createWxPay()
      }
      // let url = `ws://${location.host}:7272`

    },

    async bindClient() {
      await this.$axios.post('/Api/PayMent/bindUid', {
        client_id: this.clientId
      })

    },

    async createAlipay() {
      this.$message.loading({content: '正在创建支付宝订单', key, duration: 0})
      this.$axios.post('/Api/PayMent/Buy/createAliPayOrder', {
        gid: this.selectProduct
      }).then(value => {
        this.$message.loading({content: '已创建支付宝订单,等待支付', key, duration: 0})
        const temp = JSON.parse(value.data.data)
        this.isOpenWs = true

        this.oid = temp.oid
        this.userClose = false
        this.qrcode = `/Api/PayMent/qrCode?url=${temp.img}`
      }).catch(() => {
        this.$message.error({content: '请求超时', key, duration: 2})

      })
    },
    async createWxPay() {
      this.$message.loading({content: '正在创建微信订单', key, duration: 0})
      this.$axios.post('/Api/PayMent/Buy/createWechatOrder', {
        gid: this.selectProduct
      }).then(async value => {
        this.$message.loading({content: '已创建微信订单,等待支付', key, duration: 0})
        const temp = JSON.parse(value.data.data)
        this.isOpenWs = true
        this.oid = temp.oid
        this.userClose = false
        this.qrcode = `/Api/PayMent/qrCode?url=${temp.img}`
      }).catch(() => {
        this.$message.error({content: '请求超时', key, duration: 2})
      })
    },


    async WsClose() {

    },


    async WsMsg(e) {
      const temp = JSON.parse(e.data)
      this.Ws.send('pong')
      if (temp.type == 'payment') {
        if (this.selectPayfor == 1) {
          await this.queryAlipay()
        } else {
          await this.queryWxPay()
        }
      }

      try {
        if (temp.data.client_id != undefined) {
          this.clientId = temp.data.client_id
        }
      } catch (e) {
        console.log(1 + 1 == 1)
      }

      if (temp.type == 'connect') {
        await this.bindClient()
      }


    },


    WsErr() {
      setTimeout(async () => {
        this.Ws = await new WebSocket(`wss://${location.host}/websocket`)
        // this.Ws = await new WebSocket('wss://cs.yskam.com/websocket')
        // TODO 这是个注释
        this.Ws.onclose = this.WsClose
        this.Ws.onmessage = this.WsMsg
        this.Ws.onerror = this.WsErr
        await this.bindClient()
      }, 2000)
      this.clientId = ''
      this.isOpenWs = false
      this.Ws = null
      this.loop = 1
      this.pay_success = false
      this.oid = ''
      this.qrcode = null
      this.$message.error({content: '与服务器连接出错,请稍后重试', key, duration: 2})
    }
  },
  mounted() {


    this.selectProduct = this.Goods[0].id
    this.productName = this.Goods[0].name
    this.productPrice = `${this.Goods[0].price[0]}.${this.Goods[0].price[1] != undefined ? this.Goods[0].price[1] : '00'}`
  },

  async created() {
    this.Ws = await new WebSocket(`wss://${location.host}/websocket`)
    // this.Ws = await new WebSocket('wss://cs.yskam.com/websocket')
    // TODO 这是个注释
    this.Ws.onclose = this.WsClose
    this.Ws.onmessage = this.WsMsg
    this.Ws.onerror = this.WsErr
    const metas = document.getElementsByTagName("meta");
    metas[4].content = 'width=device-width,initial-scale=0.6'
    this.isPc = !(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent))

  },
  beforeDestroy() {
    this.Ws.close()
  }
}
</script>

<style scoped lang="less">
::v-deep {
  img {
    max-width: 100%;
  }
}
</style>